var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":"Sidedishes List"}},[_c('div',{staticClass:"custom-search d-flex justify-content-between"},[_c('div',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.isSidebarActive = true}}},[_vm._v(" Add New ")])],1),_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.sidedishes,"search-options":{
          enabled: true,
          externalQuery: _vm.searchTerm,
        },"line-numbers":true,"pagination-options":{
          enabled: true,
          perPage: _vm.pageLength,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'status')?_c('span',[_c('b-badge',{staticClass:"hover-pointer",attrs:{"variant":_vm.statusVariant(props.row.status)},on:{"click":function($event){return _vm.initdeletesidedishes(
                  props.row.id,
                  'status',
                  props.row.status
                )}}},[_vm._v(" "+_vm._s(props.row.status == 1 ? "Active" : "Inactive")+" ")])],1):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-badge',{attrs:{"variant":"primary"}},[_c('router-link',{attrs:{"to":{
                      name: 'master-sidedish-edit',
                      params: { id: props.row.id },
                    }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}})],1)],1),_c('b-badge',{staticClass:"ml-2 hover-pointer",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.initdeletesidedishes(props.row.id, 'delete')}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}})],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3', '5', '10']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1),_c('add-sidedish',{attrs:{"is-sidebar-active":_vm.isSidebarActive},on:{"update:isSidebarActive":function($event){_vm.isSidebarActive=$event},"update:is-sidebar-active":function($event){_vm.isSidebarActive=$event},"refetch-data":_vm.refetchsidedishes}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }