import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchsidedishes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/get/sidedishes', {})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  
  
    addsidedishes(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/add/sidedishes', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    
  
    updatesidedishes(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/update/sidedish', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deletesidedishes(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/delete/sidedishes', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },



    addUser(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admins/registerAssociate', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
